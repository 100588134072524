@import '../../../theme/colors.scss';

.root {
  display: grid;
  width: 100%;
  align-items: center;
  align-content: start;
  min-height: 640px;
}

.row {
  display: contents;
}

.headerCell,
.cell {
  height: 64px;
  display: flex;
  align-items: center;
  padding: 5px 25px;
  box-sizing: border-box;
  border-bottom: 1px solid var(--color-gray-lightened-30);

  white-space: nowrap;
  overflow: hidden;

  &:first-child {
    padding-left: 35px;

    &.check {
      padding-left: 12px;
    }

    &.preIcon ~ .cell:nth-child(2),
    &.preIcon ~ .headerCell:nth-child(2) {
      padding-left: 8px;
    }

    &.preIcon {
      padding-right: 0;
    }
  }
  &:last-child {
    padding-right: 35px;

    &.check {
      padding-right: 12px;
    }
  }

  &.check {
    padding-left: 12px;
    padding-right: 12px;
  }
}

.row:hover .cell {
  background: var(--color-gray-lightened-45);
}

.selected {
  &.row .cell {
    background: var(--color-blue-lightened-45);
  }
}
