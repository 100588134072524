.root {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 100%;
}

.date {
  font-size: 14px;
  font-weight: bold;
}
