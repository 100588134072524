.root {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 15px;
  align-items: start;

  strong {
    font-weight: bold;
  }
}

.description {
  word-break: break-word;
}
