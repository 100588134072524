.root {
  max-width: 670px;
  display: flex;
  flex-direction: column;
  gap: 56px;
}

.items {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.title {
  display: flex;
  width: auto;
  gap: 15px;
  align-items: center;
}
