@import '~@fontsource/inter/latin.css';
@import '~@fontsource/montserrat/latin.css';
@import 'react-big-calendar/lib/css/react-big-calendar.css';

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
html,
body {
  background: #f7f9fc;
}
body {
  height: 100vh;
  width: 100%;
  font-family: 'Inter', 'Roboto', '"Helvetica Neue"', 'Arial', 'sans-serif';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @media (min-width: 1280px) {
    min-width: 1280px;
  }
}
.inner-html {
  ul,
  ol,
  li,
  b,
  i,
  u,
  h1,
  h2,
  pre {
    margin: revert;
    padding: revert;
    font-weight: revert;
    font-style: revert;
    list-style: revert;
    border: revert;
    font-size: revert;
    font: revert;
    vertical-align: revert;
  }

  pre {
    white-space: pre-wrap;
    word-wrap: break-word;
    overflow-wrap: break-word;
  }
}
#root {
  width: 100%;
  height: 100%;
}
@media print {
  #base-layout {
    page-break-before: always;
    height: auto !important;
  }
}
