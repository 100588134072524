:root {
  // Text
  --color-text-base-raw: 9 29 61;
  --color-text-base: rgb(var(--color-text-base-raw));
  --color-text-lighten-10: rgb(var(--color-text-base-raw) / 0.8);
  --color-text-lighten-20: rgb(var(--color-text-base-raw) / 0.6);
  --color-text-lighten-30: rgb(var(--color-text-base-raw) / 0.4);

  // Gray
  --color-gray-darkened-15-raw: 66 81 102;
  --color-gray-darkened-raw: 82 97 118;
  --color-gray-base-raw: 149 163 185;
  --color-gray-lightened-20-raw: 190 198 211;
  --color-gray-lightened-30-raw: 224 228 235;
  --color-gray-darkened-15: rgb(var(--color-gray-darkened-15-raw));
  --color-gray-darkened: rgb(var(--color-gray-darkened-raw));
  --color-gray-base: rgb(var(--color-gray-base-raw));
  --color-gray-lightened-20: rgb(var(--color-gray-lightened-20-raw));
  --color-gray-lightened-30: rgb(var(--color-gray-lightened-30-raw));
  --color-gray-lightened-40: rgb(var(--color-gray-base-raw) / 0.15);
  --color-gray-lightened-45: rgb(var(--color-gray-base-raw) / 0.1);
  --color-gray-lightened-50: rgb(var(--color-gray-base-raw) / 0.05);
  --color-gray-placeholder-raw: 131 136 144;
  --color-gray-placeholder: rgb(var(--color-gray-placeholder-raw));

  // Blue
  --color-blue-darkened-30-raw: 0 49 135;
  --color-blue-darkened-10-raw: 15 94 230;
  --color-blue-base-raw: 37 117 255;
  --color-blue-lightened-5-raw: 56 129 255;
  --color-blue-lightened-10-raw: 77 142 255;
  --color-blue-darkened-30: rgb(var(--color-blue-darkened-30-raw));
  --color-blue-darkened-10: rgb(var(--color-blue-darkened-10-raw));
  --color-blue-base: rgb(var(--color-blue-base-raw));
  --color-blue-lightened-5: rgb(var(--color-blue-lightened-5-raw));
  --color-blue-lightened-10: rgb(var(--color-blue-lightened-10-raw));
  --color-blue-lightened-25: rgb(var(--color-blue-base-raw) / 0.5);
  --color-blue-lightened-35: rgb(var(--color-blue-base-raw) / 0.25);
  --color-blue-lightened-40: rgb(var(--color-blue-base-raw) / 0.15);
  --color-blue-lightened-45: rgb(var(--color-blue-base-raw) / 0.1);
  --color-blue-lightened-50: rgb(var(--color-blue-base-raw) / 0.05);

  // Red
  --color-red-base-raw: 244 54 85;
  --color-red-base: rgb(var(--color-red-base-raw));
  --color-red-lightened: rgb(var(--color-red-base-raw) / 0.1);

  // Green
  --color-green-base-raw: 22 173 146;
  --color-green-base: rgb(var(--color-green-base-raw));
  --color-green-lightened-10: rgb(var(--color-green-base-raw) / 0.9);
  --color-green-lightened-45: rgb(var(--color-green-base-raw) / 0.1);

  // Additional
  --color-additional-dark-trans-60: rgb(0 11 30 / 0.6);
  --color-additional-dark-trans-40: rgb(0 11 30 / 0.4);
  --color-additional-gray-blue-text-raw: 107 155 226;
  --color-additional-gray-blue-text: rgb(
    var(--color-additional-gray-blue-text-raw)
  );
  --color-additional-orange-raw: 247 133 0;
  --color-additional-orange: rgb(var(--color-additional-orange-raw));
  --color-additional-orange-lightened: rgb(247 133 0 / 0.1);
  --color-additional-general-background-raw: 247 249 252;
  --color-additional-general-background: rgb(
    var(--color-additional-general-background-raw)
  );
}
